import { Slot, Slottable } from "@radix-ui/react-slot";
import type { JSX } from "react";
import { forwardRef } from "react";
import { match } from "ts-pattern";
import { Icon } from "~/components/utilities/IconVariant";
import {
	type ActionVariantProps,
	type AnchorVariantProps,
	type ButtonVariantProps,
	type InteractiveIconProps,
	type TypeProps,
	interactiveAction,
	interactiveAnchor,
	interactiveButton,
} from "~/components/utilities/interactive";

interface BaseProps {
	asChild?: boolean;
}

export type AnchorProps = BaseProps & InteractiveIconProps & TypeProps & JSX.IntrinsicElements["a"];

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(function AnchorRef(props, ref) {
	const {
		variant = "link",
		size,
		color,
		style,

		startIcon,
		endIcon,
		iconSize,
		iconColor,

		asChild,

		children,
		className,
		...restProps
	} = props;

	const Comp = asChild ? Slot : "a";

	return (
		<Comp
			ref={ref}
			className={match(variant)
				.with("link", () =>
					interactiveAnchor({
						color: color as AnchorVariantProps["color"],
						className,
					}),
				)
				.with("action", () =>
					interactiveAction({
						color: color as ActionVariantProps["color"],
						size: size as ActionVariantProps["size"],
						style: style as ActionVariantProps["style"],
						className,
					}),
				)
				.otherwise(() =>
					interactiveButton({
						variant: variant as ButtonVariantProps["variant"],
						size: size as ButtonVariantProps["size"],
						color: color as ButtonVariantProps["color"],
						className,
					}),
				)}
			rel={props.target === "_blank" && !props.rel ? "noopener" : props.rel}
			{...restProps}
		>
			{startIcon && (
				<Icon
					icon={startIcon}
					size={iconSize}
					color={iconColor}
					className={children ? "mr-2" : undefined}
				/>
			)}

			<Slottable>{children}</Slottable>

			{endIcon && (
				<Icon
					icon={endIcon}
					size={iconSize}
					color={iconColor}
					className={children ? "ml-2" : undefined}
				/>
			)}
		</Comp>
	);
});
