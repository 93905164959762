import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Portal } from "@radix-ui/react-portal";
import { clsx } from "clsx";
import { useRef, useState } from "react";
import { useOnClickOutside, useScrollLock } from "usehooks-ts";
import { Icon } from "~/components/utilities/IconVariant";
import { Square } from "~/components/utilities/interactive/Square";
import { DarkLightToggle } from "~/components/utilities/layout/DarkLightToggle";
import { UserAvatar } from "~/components/utilities/layout/UserAvatar";
import { NavigationContent } from "~/components/utilities/layout/navigation/Content";
import { useEscapeKeydown } from "~/hooks/use-escape-keydown";
import { useRouteChange } from "~/hooks/use-route-change";

export function MobileNavigation() {
	const [open, setOpen] = useState<boolean>(false);

	const mainRef = useRef<HTMLDivElement>(null);

	const { lock, unlock } = useScrollLock({ autoLock: false });

	function onOpenChange(open: boolean) {
		setOpen(open);

		if (open) {
			lock();
		} else {
			unlock();
		}
	}

	useOnClickOutside(mainRef, () => onOpenChange(false));
	useRouteChange(() => onOpenChange(false));
	useEscapeKeydown(() => onOpenChange(false));

	return (
		<>
			<Square rounded="full" className="xs:hidden">
				<button type="button" onClick={() => onOpenChange(!open)}>
					<Icon icon={faBars} size="lg" />
				</button>
			</Square>

			<Portal asChild>
				<div
					ref={mainRef}
					className={clsx(
						"fixed inset-0 z-2 flex xs:hidden w-full flex-col gap-6 bg-background p-4 motion-safe:transition-transform",
						{ "-translate-x-full": !open },
					)}
				>
					<div className="flex items-center justify-between gap-4">
						<div className="flex items-center gap-x-4 gap-y-1">
							<img src="/images/logo-icon.png" alt="TOSIZE Hemlock" className="h-12 w-12" />
							<UserAvatar />
							<DarkLightToggle />
						</div>

						<Square size="large">
							<button type="button" onClick={() => onOpenChange(false)}>
								<Icon icon={faTimes} size="lg" />
							</button>
						</Square>
					</div>

					<NavigationContent expanded />
				</div>
			</Portal>
		</>
	);
}
