import { Slot } from "@radix-ui/react-slot";
import type { ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import { interactiveSquare } from "~/components/utilities/interactive";

export type SquareVariantProps = VariantProps<typeof interactiveSquare>;

interface SquareProps {
	children: ReactNode;
	className?: string;
}

export function Square(props: SquareProps & SquareVariantProps) {
	const { children, className, color, rounded, size } = props;

	return <Slot className={interactiveSquare({ color, rounded, size, className })}>{children}</Slot>;
}
