import { ErrorComponent, type ErrorComponentProps } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { LayoutComponent } from "~/components/layout";
import { PageLayout } from "~/layouts/PageLayout";

export function ErrorPageComponent(props: ErrorComponentProps) {
	const { t } = useTranslation();

	return (
		<LayoutComponent>
			<PageLayout title={t("page_has_error")} className="m-2">
				<ErrorComponent error={props.error} />
			</PageLayout>
		</LayoutComponent>
	);
}
