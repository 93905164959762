import { type Dispatch, type SetStateAction, createContext, useContext } from "react";

export type CommandInterface = {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
};

export const CommandContext = createContext<CommandInterface | null>(null);

export function useCommand() {
	const context = useContext(CommandContext);

	if (context === null) throw new Error("Command context is not available");

	return context;
}
