import { faPersonToDoor, faPersonWalkingArrowLoopLeft } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { Dialog } from "~/components/dialog/Default";
import { Button } from "~/components/utilities/interactive/Button";
import { Square } from "~/components/utilities/interactive/Square";
import { useRouteChange } from "~/hooks/use-route-change";
import { useLogout, useUser } from "~/hooks/use-user";

export function UserAvatar() {
	const user = useUser();
	const logout = useLogout();
	const [open, setOpen] = useState(false);

	useRouteChange(() => setOpen(false));

	const letters = [user.data.firstname.at(0), user.data.lastname.at(0)]
		.filter(Boolean)
		.map((letter) => letter?.toUpperCase())
		.join("");

	return (
		<>
			<Square
				size="large"
				color="background-gray"
				className="z-1 flex items-center justify-center font-bold"
			>
				<button type="button" onClick={() => setOpen(!open)}>
					{letters}
				</button>
			</Square>

			<Dialog
				open={open}
				setOpen={setOpen}
				width="xs"
				title="Weet je zeker dat je wilt uitloggen?"
				className="flex gap-4"
			>
				<Button
					className="w-full"
					variant="outline"
					startIcon={faPersonWalkingArrowLoopLeft}
					onClick={() => setOpen(false)}
				>
					Nee
				</Button>
				<Button
					className="w-full"
					color="red"
					startIcon={faPersonToDoor}
					onClick={() => logout.mutate({})}
					isLoading={logout.isPending}
				>
					Ja
				</Button>
			</Dialog>
		</>
	);
}
