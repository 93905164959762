import createFetchClient from "openapi-fetch";
import createClient from "openapi-react-query";

import { csrfMiddleware } from "./middleware/csrf-middleware";
import { errorMiddleware } from "./middleware/error-middleware";

// generated by openapi-typescript
import type { paths } from "./openapi";

export const fetchClient = createFetchClient<paths>({
	baseUrl: import.meta.env.VITE_APP_URL,
	credentials: "include",
	headers: {
		Accept: "application/json",
	},
});

fetchClient.use(errorMiddleware);
fetchClient.use(csrfMiddleware);

export const $api = createClient(fetchClient);
