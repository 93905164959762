import {
	faAddressCard,
	faChartUser,
	faChevronRight,
	faFileInvoiceDollar,
	faIndustryWindows,
	faStore,
	faTree,
} from "@fortawesome/pro-solid-svg-icons";
import { Link, useMatchRoute, useNavigate } from "@tanstack/react-router";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropDownMenu } from "~/components/utilities/DropDownMenu";
import { Icon, type IconProps } from "~/components/utilities/IconVariant";
import { interactiveSquare } from "~/components/utilities/interactive";
import { DarkLightToggle } from "~/components/utilities/layout/DarkLightToggle";
import { UserAvatar } from "~/components/utilities/layout/UserAvatar";
import { useRouteChange } from "~/hooks/use-route-change";
import { cn } from "~/hooks/use-tailwind";
import type { FileRouteTypes } from "~/routeTree.gen";

type NavigationItemType =
	| {
			to?: never;
			icon: NavigationToggableItemProps["icon"];
			title: NavigationToggableItemProps["title"];
			childItems: NavigationToggableItemProps["childItems"];
	  }
	| {
			to: NavigationLinkProps["to"];
			icon: NonNullable<NavigationLinkProps["icon"]>;
			title: NavigationLinkProps["title"];
			childItems?: never;
	  };

interface NavigationContentProps {
	expanded: boolean;
	toggleable?: boolean;
}

export function NavigationContent(props: NavigationContentProps) {
	const { expanded, toggleable } = props;
	const { t } = useTranslation();

	const items: NavigationItemType[] = [
		{ icon: faChartUser, to: "/", title: t("dashboard") },
		{ icon: faAddressCard, to: "/profile", title: t("profile") },
		{
			icon: faStore,
			title: t("sales"),
			childItems: [
				{ to: "/orders", title: t("orders") },
				{ to: "/customers", title: t("customers") },
			],
		},
		{
			icon: faFileInvoiceDollar,
			title: t("purchase"),
			childItems: [
				{ to: "/purchase-orders/items", title: t("purchase_order_items") },
				{ to: "/purchase-orders", title: t("purchase_orders") },
			],
		},
		{
			icon: faTree,
			title: t("stock"),
			childItems: [
				{ to: "/materials", title: t("materials") },
				{ to: "/rest-sheets", title: t("rest_sheets") },
			],
		},
		{
			icon: faIndustryWindows,
			title: t("production"),
			childItems: [
				{ to: "/optimizable-items", title: t("optimizable_items") },
				{ to: "/production-batches", title: t("production_batches") },
			],
		},
	];

	return (
		<div
			className={cn("scrollbar-none flex grow snap-y flex-col overflow-y-auto *:snap-start", {
				"gap-2": expanded,
				"gap-1": !expanded,
			})}
		>
			{expanded
				? items.map(({ to, icon, title, childItems }) => (
						<Fragment key={title}>
							{childItems?.length ? (
								<NavigationToggableItem
									icon={icon}
									title={title}
									childItems={childItems}
									expanded={expanded}
									toggleable={toggleable}
								/>
							) : (
								<NavigationLink to={to ?? ""} icon={icon} title={title} expanded={expanded} />
							)}
						</Fragment>
					))
				: items.map((item) => <NavigationItem key={item.title} {...item} />)}

			{toggleable && (
				<div
					className={cn("relative mt-auto flex shrink-0 items-center gap-x-2 gap-y-1", {
						"flex-col": !expanded,
					})}
				>
					<DarkLightToggle />
					<UserAvatar />
				</div>
			)}
		</div>
	);
}

function NavigationItem(props: NavigationItemType) {
	const { to, icon, title, childItems } = props;
	const navigate = useNavigate();
	const matchRoute = useMatchRoute();
	const isActive = to ? matchRoute({ to }) : false;
	const isChildActive = childItems?.some((child) => matchRoute({ to: child.to }));
	const [subMenuOpen, setSubMenuOpen] = useState(false);

	useRouteChange(() => setSubMenuOpen(false));

	return (
		<DropDownMenu
			key={title}
			open={subMenuOpen}
			onOpenChange={setSubMenuOpen}
			contentProps={{ side: "right", className: "min-w-40" }}
			items={
				childItems?.length
					? childItems.map((child) => ({
							label: child.title,
							href: {
								to: child.to,
								activeOptions: { exact: true, includeSearch: false },
							},
						}))
					: [{ label: title, href: { to } }]
			}
		>
			<button
				onClick={() => {
					if (!childItems?.length) navigate({ to });
				}}
				onMouseEnter={() => setSubMenuOpen(true)}
				type="button"
				className={interactiveSquare({
					className: cn({
						"bg-gray-3 text-primary-11": isActive || isChildActive,
					}),
					color: "background-gray",
					size: "large",
				})}
			>
				<Icon icon={icon} />
			</button>
		</DropDownMenu>
	);
}

interface NavigationToggableItemProps {
	icon: IconProps["icon"];
	title: string;
	childItems: Pick<NavigationLinkProps, "to" | "title">[];
	expanded: boolean;
	toggleable?: boolean;
}

function NavigationToggableItem(props: NavigationToggableItemProps) {
	const { icon, title, childItems, expanded, toggleable } = props;
	const matchRoute = useMatchRoute();
	const isActive = childItems.some((child) => matchRoute({ to: child.to, fuzzy: true }));
	const [subMenuOpen, setSubMenuOpen] = useState(isActive);
	const open = toggleable ? subMenuOpen : true;

	return (
		<>
			<button
				type="button"
				onClick={() => setSubMenuOpen(!subMenuOpen)}
				className={cn(
					"flex items-center gap-2 rounded px-2 py-1 text-gray-11 safe-motion:transition-colors hover:bg-gray-4",
					{ "bg-gray-3 text-primary-11": isActive },
				)}
			>
				<Icon icon={icon} className="shrink-0" />
				<span>{title}</span>
				{toggleable && (
					<Icon
						size="sm"
						icon={faChevronRight}
						className={cn("ml-auto motion-safe:transition-transform", {
							"rotate-90": open,
						})}
					/>
				)}
			</button>

			{open && (
				<div className="ml-4 flex flex-col gap-1 border-gray-6 border-l">
					{childItems.map((child) => (
						<NavigationLink
							key={child.title}
							to={child.to}
							title={child.title}
							expanded={expanded}
							className="ml-3"
						/>
					))}
				</div>
			)}
		</>
	);
}

interface NavigationLinkProps {
	to: FileRouteTypes["to"];
	icon?: IconProps["icon"];
	title: string;
	expanded: boolean;
	className?: string;
}

function NavigationLink(props: NavigationLinkProps) {
	const { to, icon, title, expanded, className } = props;

	return (
		<Link
			to={to}
			activeOptions={{ exact: true, includeSearch: false }}
			activeProps={{ className: "text-primary-11 bg-gray-3" }}
			className={cn(
				"flex items-center gap-2 rounded px-2 py-1 text-gray-11 safe-motion:transition-colors hover:bg-gray-4",
				className,
			)}
		>
			{icon && <Icon icon={icon} className="shrink-0" />}
			{expanded && title}
		</Link>
	);
}
