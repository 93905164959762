import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import { NavigationContent } from "~/components/utilities/layout/navigation/Content";
import { cn } from "~/hooks/use-tailwind";

export function Navigation() {
	const isSmall = useMediaQuery("(min-width: 1024px)");
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState<boolean>(window.innerWidth >= 1024);

	useEffect(() => {
		function handleResize() {
			setExpanded(window.innerWidth >= 1024);
		}

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div
			className={cn(
				"sticky top-0 z-2 xs:flex hidden h-full max-h-dvh shrink-0 flex-col gap-6 py-4 pl-4 motion-safe:transition-all",
				{ "w-56": expanded, "w-[56px]": !expanded },
			)}
		>
			{isSmall ? (
				<button
					type="button"
					onClick={() => setExpanded(!expanded)}
					className="h-12 w-12 rounded-md outline-none focus-visible:ring-1 focus-visible:ring-primary-7"
				>
					<img src="/images/logo-icon.png" alt="TOSIZE Hemlock" />
				</button>
			) : (
				<img src="/images/logo-icon.png" alt={t("app_name")} className="h-12 w-12" />
			)}

			<NavigationContent expanded={expanded} toggleable />
		</div>
	);
}
