import type { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type VariantProps, tv } from "tailwind-variants";

export type IconVariantProps = VariantProps<typeof iconVariant>;

const iconVariant = tv({
	variants: {
		color: {
			current: "text-current",
			dark: "text-gray-12",
			primary: "text-primary-9",
			white: "text-white",
			red: "text-red-9",
			gray: "text-gray-9",
		},
		loading: {
			true: "safe-motion:animate-spin",
		},
	},
	defaultVariants: {
		color: "current",
		loading: false,
	},
});

export interface IconProps {
	icon: IconProp;
	size?: SizeProp | "default";
	color?: IconVariantProps["color"];
	loading?: boolean;
	className?: string;
}

export function Icon({ icon, size, color, loading, className }: IconProps) {
	return (
		<FontAwesomeIcon
			className={iconVariant({ color, loading, className })}
			icon={loading ? faSpinnerThird : icon}
			size={size === "default" ? undefined : size}
			fixedWidth
		/>
	);
}
