import {
	faHourglass,
	faHourglassEnd,
	faHourglassHalf,
	faHourglassStart,
} from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutComponent } from "~/components/layout";
import { Icon } from "~/components/utilities/IconVariant";
import { Typography } from "~/components/utilities/Typography";
import { PageLayout } from "~/layouts/PageLayout";

export function PendingPageComponent() {
	const { t } = useTranslation();

	const [currentIndex, setCurrentIndex] = useState(0);
	const icons = useMemo(() => {
		return [faHourglassStart, faHourglassHalf, faHourglassEnd, faHourglass];
	}, []);
	const icon = icons[currentIndex];

	useEffect(() => {
		const interval = setInterval(() => {
			return setCurrentIndex((prevIndex) => (prevIndex + 1) % icons.length);
		}, 500);

		return () => clearInterval(interval);
	}, [icons.length]);

	return (
		<LayoutComponent>
			<PageLayout title={t("page_is_loading")} className="px-2">
				<div className="flex grow flex-col items-center justify-center gap-4 p-4">
					<Icon icon={icon ?? faHourglassStart} size="8x" className="text-primary-11" />
					<Typography variant="h5">{t("page_is_loading")}</Typography>
				</div>
			</PageLayout>
		</LayoutComponent>
	);
}
