import { Slottable } from "@radix-ui/react-slot";
import clsx from "clsx";
import { type HTMLAttributes, createElement, forwardRef } from "react";
import { Icon } from "~/components/utilities/IconVariant";
import type { InteractiveIconProps } from "~/components/utilities/interactive";

type ComponentAttributes =
	| HTMLAttributes<HTMLHeadingElement>
	| HTMLAttributes<HTMLParagraphElement>
	| HTMLAttributes<HTMLSpanElement>;

type VariantProps =
	| {
			variant?: "text";
			size?: "lg" | "base" | "sm" | "xs";
			weight?: "normal" | "bold";
	  }
	| {
			variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
			size?: never;
			weight?: never;
	  };

interface Props {
	component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div";
	color?: "primary" | "gray";
	contrast?: "high" | "low";
	style?: "normal" | "italic";
}

export type TypographyProps = Props & ComponentAttributes & VariantProps & InteractiveIconProps;

export const Typography = forwardRef<ComponentAttributes, TypographyProps>(
	function Ref(props, ref) {
		const {
			variant = "text",
			component = variant === "text" ? "p" : variant,
			size = variant === "text" ? "base" : undefined,
			weight = "normal",
			color = "gray",
			contrast = "high",
			style = "normal",

			startIcon,
			endIcon,
			iconSize,
			iconColor,

			children,
			className,
			...remainingProps
		} = props;

		return createElement(
			component,
			{
				...remainingProps,
				className: clsx(className, "break-words", {
					"font-extrabold font-sans text-5xl": variant === "h1",
					"font-extrabold font-sans text-4xl": variant === "h2",
					"font-extrabold font-sans text-3xl": variant === "h3",
					"font-extrabold font-sans text-2xl": variant === "h4",
					"font-bold font-sans text-xl": variant === "h5",
					"font-bold font-sans text-lg": variant === "h6",
					"font-sans": variant === "text",

					"text-lg": size === "lg",
					"text-base": size === "base",
					"text-sm": size === "sm",
					"text-xs": size === "xs",

					"text-primary-11": color === "primary" && contrast === "low",
					"text-primary-12": color === "primary" && contrast === "high",
					"text-gray-11": color === "gray" && contrast === "low",
					"text-gray-12": color === "gray" && contrast === "high",

					"font-bold": weight === "bold",
					italic: style === "italic",
				}),
				ref,
			},
			<>
				{startIcon && (
					<Icon
						icon={startIcon}
						size={iconSize}
						color={iconColor}
						className={children ? "mr-2" : undefined}
					/>
				)}

				<Slottable>{children}</Slottable>

				{endIcon && (
					<Icon
						icon={endIcon}
						size={iconSize}
						color={iconColor}
						className={children ? "ml-2" : undefined}
					/>
				)}
			</>,
		);
	},
);
