import type { JSXElementConstructor, PropsWithChildren, ReactNode } from "react";

interface ComposeProps {
	components: Array<JSXElementConstructor<PropsWithChildren<unknown>>>;
	children: ReactNode;
}

export function Compose(props: ComposeProps) {
	const { components = [], children } = props;

	return components.reduceRight(
		(acc, Comp) => <Comp key={components.indexOf(Comp)}>{acc}</Comp>,
		children,
	);
}
