import { type FC, type PropsWithChildren, useCallback, useEffect, useState } from "react";
import { CommandContext } from "~/components/command/context";
import { Dialog } from "~/components/dialog/Default";

export const CommandProvider: FC<PropsWithChildren> = (props) => {
	const { children } = props;

	const [open, setOpen] = useState<boolean>(false);

	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			const inputs = ["input", "textarea"];
			const tagName =
				event.target instanceof HTMLElement ? event.target.tagName.toLowerCase() : undefined;
			const notFormElement = (tagName && !inputs.includes(tagName)) || !tagName;

			if (notFormElement || open) {
				if ((event.metaKey || event.ctrlKey) && event.key === "k") {
					setOpen((prev) => !prev);
				}
			}
		},
		[open],
	);

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, [onKeyDown]);

	return (
		<CommandContext.Provider
			value={{
				open,
				setOpen,
			}}
		>
			{children}

			<Dialog open={open} setOpen={setOpen} title="Snel menu">
				i am speed
			</Dialog>
		</CommandContext.Provider>
	);
};
