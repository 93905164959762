import type { Middleware } from "openapi-fetch";

function getToken() {
	const cookie = document.cookie
		.split("; ")
		.find((row) => row.startsWith("XSRF-TOKEN="))
		?.split("=");

	if (cookie?.[1]) {
		return decodeURIComponent(cookie[1]);
	}

	return undefined;
}

export const csrfMiddleware: Middleware = {
	onRequest: async ({ request }) => {
		const token = getToken();

		if (token) {
			request.headers.set("x-xsrf-token", token);
		}
	},
	onResponse: async ({ response }) => {
		if (response.status === 419) {
			await fetch(`${import.meta.env.APP_URL}/sanctum/csrf-cookie`, {
				credentials: "include",
			});
		}

		// let react-query handle retries
	},
};
